import { createApp } from 'vue'
import App from './App.vue'
import Toaster from "@meforma/vue-toaster"; //всплывающие уведомления
import Maska from 'maska'
import Pusher from "pusher-js";
import { createI18n } from 'vue-i18n';
import VueSmoothScroll from 'vue3-smooth-scroll'

import { languages } from './locales/settings.js'
import { defaultLocale } from './locales/settings.js'
const messages = Object.assign(languages);
import { initYandexMetrika } from 'yandex-metrika-vue3';
import router from "./router";


const app = createApp(App)
app.config.globalProperties.$pusher = new Pusher('9eed0f9c76dc0800dc4d', {
    cluster: 'ap1',
    // Additional options if needed
});

const i18n = createI18n({
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    locale: defaultLocale,
    fallbackLocale: 'kz',
    warnHtmlInMessage: 'off',
    messages,
});

app.use(Toaster)
    .use(router)
    .use(initYandexMetrika, {
        id: 96679303,
        env: window.location.origin === 'http://localhost:8080' ? 'dev' : 'production',
    })
    .use(Maska)
    .use(i18n)
    .use(VueSmoothScroll)
    .mount('#app')
