import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        component: () => import("./App.vue"),
        children: [
            {
                path: '',
                component: () => import('./Home.vue'),
            },
            {
                path: '/survey',
                component: () => import('./Survey.vue'),
            },
            {
                path: '/survey-end',
                component: () => import('./SurveyEnd.vue'),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
